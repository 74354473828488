.login-content {
  border-radius: 0px !important;
  border: 0px solid !important;
  padding-top: 10vh;
}

.signin {
  @media screen and (max-width: 906px) {
    .bg-image {
      background-size: cover;
      min-height: 80vh;
    }
  }

  .bg-image {
    background-image: url(../../../../assets/img/auth_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card-login {
    max-width: 400px !important;
  }
  .login-alert {
    margin-left: 2px;
    font-size: 0.8em;
  }

  .info-top-icon {
    width: 40px;
    height: auto;
    object-fit: contain;
  }

  .content-footer {
    height: 25vh;
    width: 90%;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-bottom: 2% !important;
    border-width: 1px;
    background-color: #fff !important;
    border-color: #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .on-secondary {
    color: white;
    font-weight: 600;
  }

  h2,
  h3,
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h6 {
    font-weight: 400;
    text-transform: none;
  }

  .bottom-cgu {
    max-width: 380px;
  }

  .other-actions {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-action {
    cursor: pointer;
    font-size: small;
    font-family: Montserrat;
    font-weight: 300;
    color: #000000;
    margin-bottom: 10px;
    text-align: center;
  }

  .bottom-action:hover {
    text-decoration: underline;
    color: #037cc4;
  }

  .password-input {
    position: relative;
    cursor: pointer;
  }

  .input-addon {
    position: absolute;
    width: 20px;
    height: auto;
    object-fit: contain;
    right: 10px;
    bottom: 15px;
  }

  .auth-title {
    font-size: 1.2em !important;
  }
}
