.cardBord {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 4px 4px 4px 4px rgba(213, 213, 213, 0.5);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 250px;
  margin-left: 22px;
}
.imageBord {
  width: 60px;
  height: 60px;
  background-color: #2ecc71;
  border-radius: 8px;
  display: flex;
  padding: 8px;
}
.imageBord img {
  margin: 0px auto;
}
.valeurBord {
  width: 150px;
}
.valeurBord div:nth-child(2n + 1) {
  font-size: 24px;
}
