.space-left {
  margin-left: 20px;
}

.creation-form {
  margin-top: 20px;
}

.switch-input {
  display: flex;
  height: 60px;
  align-items: center;
}

.list-Utilisateurs .css-1j70apf-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 0px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  height: 34px;
  width: 100%;
  color: #373737;
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  background: white;
  border: 1px solid #f1f1f1f1;
  border-radius: 35px;
}
.list-Utilisateurs .css-151t60b-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 0px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  height: 34px;
  width: 100%;
  color: #373737;
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  background: white;
  border: 1px solid #f1f1f1f1;
  border-radius: 35px;
}

.list-Utilisateurs .css-1f6hjcp-container {
  background-color: #00a8ff;
}

.list-Utilisateurs .css-151t60b-control:hover {
  border-color: none !important;
}
.list-Utilisateurs .css-151t60b-control:focus {
  border-color: none !important;
}

.list-Utilisateurs .filter-input {
  width: 200px !important;
  height: 35px;
  margin-bottom: 11px !important;
  background-color: #ffffff;
}

.list-Utilisateurs .css-167ug0s-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: 0px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  color: #373737;
  font-size: 12px;
  font-weight: 400;
  width: 192px;
  height: 39px;
  background: #f8f8f8;
  border: 1px solid #f1f1f1f1;
  border-radius: 35px;
}
.list-Utilisateurs .css-167ug0s-control:hover {
  border-color: none !important;
}
.list-Utilisateurs .css-1izjvq5-control {
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border-width: 0px !important;
  cursor: default !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  -webkit-box-pack: justify !important;
  box-shadow: none !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  min-height: 38px !important;
  outline: 0 !important;
  position: relative !important;
  -webkit-transition: all 100ms !important;
  transition: all 100ms !important;
  box-sizing: border-box !important;
  width: 192px !important;
  height: 39px !important;
  color: #373737 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background: #f8f8f8 !important;
  border: 1px solid #f1f1f1f1 !important;
  border-radius: 35px !important;
}

.sousTitle {
  font-size: 21px;
  color: #707070;
  letter-spacing: 0;
  margin-left: 16px;
}
.deleteRow {
  font-size: 18px;
  color: #4a4a4a;
  letter-spacing: 0;
  cursor: pointer;
  margin-top: 20px;
}
.deleteRow:hover {
  font-weight: bold;
  font-size: 18px;
  color: #4a4a4a;
  letter-spacing: 0;
  cursor: pointer;
}
.btnBack {
  background-color: transparent !important;
  font-size: 18px !important;
  color: #575757 !important;
  letter-spacing: 0 !important;
}
.btnBack:hover {
  color: #575757 !important;
  box-shadow: none !important;
}
.btnNext {
  background-color: transparent !important;
  font-size: 18px !important;
  color: white !important;
  border: 2px solid white !important;
  letter-spacing: 0 !important;
  border-radius: 8px !important;
}
.btnNext:hover {
  background-color: red !important;
  color: white !important;
  border: 2px solid red !important;
  letter-spacing: 0 !important;
  border-radius: 8px !important;
}
.btnHome {
  background-color: transparent !important;
  font-size: 18px !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  letter-spacing: 0 !important;
  border-radius: 8px !important;
}
.btnHome:hover {
  background-color: #2ecc71 !important;
  color: white !important;
  border: 2px solid #2ecc71 !important;
  letter-spacing: 0 !important;
  border-radius: 8px !important;
}

.creation-plusieur-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.creation-plusieur-form .title {
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
}
.details .css-167ug0s-control {
  width: 310px !important;
}
.details .css-1chxlti-container {
  order: 1px solid #e3e3e3;
  height: 52px;
  width: 310px !important;
}
.list-Utilisateurs .css-151t60b-control:hover {
  border-color: hsl(0, 0%, 70%);
}
.list-Utilisateurs .css-1chxlti-container {
  background-color: transparent !important;
  margin-left: 7px;
}

.list-Utilisateurs .css-1f6hjcp-container {
  background-color: #00a8ff;
}

.list-Utilisateurs .css-151t60b-control:hover {
  border-color: none !important;
}
.list-Utilisateurs .css-151t60b-control:focus {
  border-color: none !important;
}

.details table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.details td,
.details th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.details tr:nth-child(even) {
  background-color: #dddddd;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
// .header .link1 {
//   width: 310px !important;
// }
// .header .link2 {
//   width: 310px !important;
// }

.btnTickit {
  background-color: transparent !important;
  font-size: 18px !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  letter-spacing: 0 !important;
  border-radius: 8px !important;
}
.btnTickit:hover {
  background-color: #0095c9 !important;
  color: white !important;
  border: 2px solid #0095c9 !important;
  letter-spacing: 0 !important;
  border-radius: 8px !important;
}

.btnChangeStatus {
  background-color: transparent !important;
  font-size: 18px !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  letter-spacing: 0 !important;
  border-radius: 8px !important;
  cursor: pointer;
}
.btnChangeStatus:hover {
  background-color: #707070 !important;
  color: white !important;
  border: 2px solid #707070 !important;
  letter-spacing: 0 !important;
  border-radius: 8px !important;
}

.ModalConfirmation .btnConfirmation {
  width: 211px !important;
  height: 60px !important;
  font-size: 18px !important;
  border-radius: 35px !important;
  padding: 0px !important;
  margin-left: 0px !important;
  background-color: #0095c9;
}
.ModalConfirmation .btnConfirmation:hover {
  background-color: #01739c !important;
}

.default-placeholder {
  border-radius: 30px !important;
  box-shadow: none !important;
  background-color: #ffffff;
  border: solid 1px #e5e9f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: hsl(0, 0%, 20%);
  justify-content: center;
  padding: 8px 12px !important;
  letter-spacing: 0.2px;
  font-size: 100%;
  box-sizing: border-box;
  font-weight: bold;
  width: 210px;
  border-radius: 35px;
  min-width: none !important;
}

.default-placeholder .text {
  color: hsl(0, 0%, 50%) !important;
  min-width: 0px !important;
}
.default-placeholder .text .dates-container {
  color: hsl(0, 0%, 20%) !important;
  font-weight: bold;
}

.btnClose {
  width: 13px;
  position: absolute;
  z-index: 1;
  right: 41px;
  top: 40px;
  cursor: pointer;
}
.export-btn {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 35px;
  padding: 7px 25px;
}
.export-btn:hover {
  color: #ffffff;
  border: 2px solid #27ae60;
  background-color: #27ae60;
  border-radius: 35px;
  padding: 7px 25px;
}
.css-1wa3eu0-placeholder {
  color: #666666 !important;
  margin-left: 10px !important;
}
@media only screen and (max-width: 1060px) {
  .filters-view {
    justify-content: flex-start !important;
  }
  .header {
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    display: block;
  }
  .header .link1 {
    margin-top: 20px !important;
  }
  .header .link2 {
    margin-top: 40px !important;
  }
  .header .link3 {
    margin-top: 40px !important;
  }
}

.details span {
  color: #fff;
}
